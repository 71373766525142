import { memo, type ComponentType, type JSX } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './Router.tsx';

import { UserLoadingError } from './components/UserLoadingError.tsx';

import { LoadingScreen } from './components/LoadingScreen.tsx';

import { SignUpForTelegramChannel } from './components/SignUpForTelegramChannel.tsx';

import { useUserContext } from './contexts/user.tsx';
import { useSubscribeContext } from './contexts/subscribe.tsx';
import { useMainChannelSubscriptionContext } from './contexts/main-channel-subscription.tsx';
import { QR } from './components/QR.tsx'
import { Telegram } from './svg/sidebar/Telegram.tsx';

function App(): JSX.Element | null {
	const userContext = useUserContext();

	const mainChannelSubscriptionContext = useMainChannelSubscriptionContext();

	const subscribeContext = useSubscribeContext();

	if (
		userContext.isUserLoading === true ||
		subscribeContext === null ||
		subscribeContext.isIsSubscribeLoading === true ||
		mainChannelSubscriptionContext === null ||
		mainChannelSubscriptionContext.isMainChannelSubscriptionLoading ===
			true ||
		typeof mainChannelSubscriptionContext.mainChannelSubscription ===
			'undefined'
	) {
		return <LoadingScreen />;
	}

	if (
		mainChannelSubscriptionContext.isMainChannelSubscriptionError === true
	) {
		console.error(
			'Error loading mainChannelSubscription:',
			mainChannelSubscriptionContext.mainChannelSubscriptionError
		);
	}

	if (mainChannelSubscriptionContext.mainChannelSubscription !== true) {
		return <SignUpForTelegramChannel />;
	}

	if (userContext.isUserLoadingError === true) {
		console.error('Error loading user:', userContext.userError);

		return <UserLoadingError />;
	}

	if (subscribeContext.isIsSubscribeLoadingError === true) {
		console.error(
			'Error loading isSubscribe:',
			subscribeContext.isSubscribeLoadingError
		);
	}

	return (
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	);
}

export default memo(App) as ComponentType;
