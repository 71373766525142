import {
	memo,
	useMemo,
	type JSX,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';

import { useGetMainChannelSubscriptionQuery } from '../api/apiSlice.ts';
import { useUserContext } from './user.tsx';
import type { SerializedError } from '@reduxjs/toolkit';
import type {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryActionCreatorResult,
	QueryDefinition,
} from '@reduxjs/toolkit/query';

type MainChannelSubscriptionContextValue = {
	mainChannelSubscription: boolean | undefined;
	refetchMainChannelSubscription: () => QueryActionCreatorResult<
		QueryDefinition<
			number | undefined,
			BaseQueryFn<
				string | FetchArgs,
				unknown,
				FetchBaseQueryError,
				// eslint-disable-next-line @typescript-eslint/ban-types
				{},
				FetchBaseQueryMeta
			>,
			never,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			'v1'
		>
	>;
	isMainChannelSubscriptionError: boolean;
	mainChannelSubscriptionError:
		| FetchBaseQueryError
		| SerializedError
		| undefined;
	isMainChannelSubscriptionUninitialized: boolean;
	isMainChannelSubscriptionLoading: boolean;
};

const MainChannelSubscriptionContext =
	createContext<MainChannelSubscriptionContextValue | null>(null);

export function useMainChannelSubscriptionContext(): null | MainChannelSubscriptionContextValue {
	return useContext(MainChannelSubscriptionContext);
}

function _MainChannelSubscriptionProvider({
	children,
}: PropsWithChildren): JSX.Element {
	const userContext = useUserContext();

	const {
		data: mainChannelSubscription,
		isError: isMainChannelSubscriptionError,
		error: mainChannelSubscriptionError,
		isUninitialized: isMainChannelSubscriptionUninitialized,
		isLoading: isMainChannelSubscriptionLoading,
		refetch: refetchMainChannelSubscription,
	} = useGetMainChannelSubscriptionQuery({
		userId: userContext.user?.id,
		language: userContext.user?.bio?.language,
	}, {
		skip: typeof userContext.user?.id !== 'number',
	});

	const value = useMemo<MainChannelSubscriptionContextValue>(() => {
		return {
			mainChannelSubscription,
			isMainChannelSubscriptionError,
			mainChannelSubscriptionError,
			isMainChannelSubscriptionUninitialized,
			isMainChannelSubscriptionLoading,
			refetchMainChannelSubscription,
		};
	}, [
		isMainChannelSubscriptionError,
		isMainChannelSubscriptionLoading,
		isMainChannelSubscriptionUninitialized,
		mainChannelSubscription,
		mainChannelSubscriptionError,
		refetchMainChannelSubscription,
	]);

	return (
		<MainChannelSubscriptionContext.Provider value={value}>
			{children}
		</MainChannelSubscriptionContext.Provider>
	);
}

export const MainChannelSubscriptionProvider = memo(
	_MainChannelSubscriptionProvider
);
